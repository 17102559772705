<template>
  <v-card flat>
    <v-card-text>
      <v-expansion-panels>
        <template v-if="versions.length">
          <v-expansion-panel v-for="(version, index) in versions" :key="index" @click="getVersion(version)">
            <v-expansion-panel-header>
              {{ version.user?.displayName }} made {{ $pluralize('changes', version.count, true) }} on
              {{ version.createdAt | date }} at
              {{ version.createdAt | time }}
              <v-spacer />
              {{ version.version }}
            </v-expansion-panel-header>

            <v-expansion-panel-content :key="version.key">
              <v-list-item v-for="(items, key) in version.diff" :key="key">
                <v-list-item-content>
                  <v-list-item-title> {{ key }}: </v-list-item-title>
                  <template v-for="(item, index) in items">
                    <v-list-item-subtitle :key="index">
                      <span v-if="item.key">{{ item.key }}:</span> {{ item.type }} {{ item.value }}
                    </v-list-item-subtitle>
                  </template>
                </v-list-item-content>
              </v-list-item>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </template>

        <v-list-item v-else>
          <v-list-item-content class="text-center">
            <v-list-item-title>
              No changes were made since version tracking was implemented on {{ createdAt | date }}
            </v-list-item-title>

            <v-list-item-title>
              Location created by {{ item.createdBy | userName }} on {{ item.createdAt | date }} at
              {{ item.createdAt | time }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script>
import { LocationService } from '@tutti/services';

export default {
  name: 'LocationHistory',

  data() {
    return {
      created: false,
      item: {},
      diff: [],
      versions: [],
      createdAt: '2021-06-21T08:25:58.006Z', // date of production push
    };
  },

  async created() {
    await this.getVersions();
    this.created = true;
  },

  methods: {
    async getVersions() {
      const response = await LocationService.getVersions({ locationId: this.$route.params.id });

      if (response) {
        this.item = response.data.location;
        this.$setTitle(this.item.name);
        this.versions = response.data.versions;
      }
    },
    async getVersion(version) {
      if (!this.$hasValue(version.diff)) {
        const response = await LocationService.getVersion({
          locationId: this.item._id,
          version: version.version,
        });

        if (response) {
          version.diff = response.data;
        }
      }
    },
  },
};
</script>
